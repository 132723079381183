<template>
	<div class="send-info">
		<div class="send-info-top">
			<el-popover placement="top-start" width="200" trigger="click" v-model="visible">
				<div class="expression-box">
					<div class="meme-list">
						<div class="meme-list-item-small pointer" v-for="(item, index) in emojiList" :key="index"
							@click="selectEmoji(item)" :class="(index + 1) % 8 == 0 ? 'm0R' : ''">
							<span>{{item}}</span>
						</div>
					</div>
					<div class="meme-list">
						<div class="meme-list-item pointer" v-for="(item, index) in userMemeList" :key="index"
							@click="selectMeme(item)" :class="(index + 1) % 5 == 0 ? 'm0R' : ''">
							<img :src="item.imageUrl" />
						</div>
					</div>
				</div>
				<div slot="reference">
					<img src="@/assets/images/ic_biaoqing3@2x.png" @click="getUserMeme" />
				</div>
			</el-popover>
			<el-upload class="upload-demo" :action="host" :show-file-list="false" :on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload" :data="ossForm" multiple>
				<img src="@/assets/images/ic_wenjian3@2x.png" />
			</el-upload>
		</div>
		<textarea v-if="!dragStatus" class="info-input" placeholder="请输入内容" v-model="content" @dragenter.prevent="dragEnter"
			@drop.prevent="dragOver" @keyup.enter.prevent="send"></textarea>
		<el-upload v-if="dragStatus" class="upload-demo" drag :action="host" :show-file-list="false"
			:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :data="ossForm" multiple>
		</el-upload>
		<div class="send-button" @click="send">发送</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import api from "@/api/api";
	export default {
		props: {
			targetUser: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				content: "", //发送的文本内容
				type: null, //群组 个人type
				msgType: "TEXT", //消息  默认文本格式
				id: "", //判断是群组还是个人的目标id  两个值传过来不一样
				dragStatus: false, //拖拽显示和隐藏
				ossForm: {}, //上传的参数
				host: "", //上传的地址
				openForm: {
					openTitle: "", //发送图片你文件 的标题 格式 发送给xxx
					openContent: "", //发送的内容
				},
				fileForm: {
					//上传的一些参数
					fileUrl: "", //发送图片 文件 地址url
					fileSize: "", //文件大小
					fileSuffix: "", //文件格式
					fileName: "", //文件名称
				},
				sendFormat: "文本", //判断是发送的消息文本 图片 文件 默认为文本
				emojiList: [
					'😃', '😀', '😊', '😉', '😍', '😘', '😚', '😜', '😝', '😳', '😁', '😔', '😌', '😒', '😟', '😞', '😣', '😢',
					'😂', '😭', '😪', '😰', '😅', '😓'
				],
				userMemeList: [], //表情列表
				visible: false, //表情弹窗
				loginMsg: {},
				base64Data: null
			};
		},
		computed: {
			...mapState({
				//获取到vuex融云实例化的数据
				RongIMClient: (state) => state.rongyun.RongIMClient,
			}),
		},
		watch: {
			//融云的实例化
			RongIMClient(newVal, oldVal) {
				// console.log("新值", newVal);
				oldVal = newVal;
			},
			//选中的联系人信息
			targetUser(newVal, oldVal) {
				this.setSendType();
				oldVal = newVal;
			},
			//判断dragStatus  来判断是上传的文件图片还是文本
			dragStatus(newVal, oldVal) {
				if (!newVal) {
					this.sendFormat = "文本";
				} else {
					this.sendFormat = "文件";
				}
			},
		},
		created() {
			//获取当前的个人信息 用来给发送添加头像 不需要请求
			this.loginMsg = JSON.parse(localStorage.getItem('loginMsg'))
		},
		methods: {
			//设置发送的类型  个人 CONVERSATION_TYPE.PRIVATE  群组 CONVERSATION_TYPE.GROUP
			//设置id  个人memberId 群组id
			//设置发送给对方图片和文档的标题
			setSendType() {
				if (this.targetUser) {
					//设置弹窗的标题以及弹窗文件名称
					this.openForm.openTitle = this.targetUser.groupName ?
						this.targetUser.groupName :
						this.targetUser.nickName;
					this.openForm.openTitle = "发送给" + this.openForm.openTitle;
					//自己要设置的 个人 从点击过来的地方设置 from
					if (this.targetUser.from == "person") {
						this.type = "PRIVATE";
						this.id = this.targetUser.memberId;
					}
					//自己要设置的 群组 从点击过来的地方设置 from
					if (this.targetUser.from == "group") {
						this.id = this.targetUser.id;
						this.type = "GROUP";
					}
				}
			},
			//获取用户表情包
			getUserMeme() {
				this.$api.other.getUserCollectionExpression({
					pageNo: 1,
					pageSize: 10000
				}).then((res) => {
					// console.log("表情包", res.result.records);
					if (res.result.records.length > 0) {
						this.userMemeList = res.result.records;
					} else {
						this.userMemeList = [];
					}
				});
			},
			selectEmoji(val) {
				this.content += val;
			},
			//选中的表情
			selectMeme(val) {
				//  this.visible = false
				this.sendFormat = "表情";
				this.fileForm.fileName = val.detailName; //赋值文件名
				this.fileForm.fileSuffix = this.getFilesuffix(val.imageUrl); //赋值格式
				this.fileForm.fileUrl = val.imageUrl;
				this.fileForm.packageId = val.expressionDetailId;
				this.fileForm.stickerId = val.id;
				this.send();
				this.visible = false;
			},
			//拖拽进入
			dragEnter(e) {
				this.dragStatus = true;
			},
			//拖拽鼠标松开
			dragOver(e) {
				//阻止浏览器默认行为 否则会在浏览器打开这个图片
				// console.log("鼠标松开");
			},
			//截取文件后缀 获取格式
			getFilesuffix(name) {
				let first = name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
				let namelength = name.length; //取到文件名长度
				let filesuffix = name.substring(first + 1, namelength); //截取获得后缀名
				return filesuffix;
			},
			//上传图片前
			beforeAvatarUpload(file) {
				this.openForm.openContent = file.name; //赋值文件名
				this.fileForm.fileName = file.name; //赋值文件名
				this.fileForm.fileSuffix = this.getFilesuffix(file.name); //赋值格式
				// console.log("========文件格式",this.fileForm.fileSuffix,file.size)
				this.fileForm.fileSize = file.size; //赋值文件大小
				//判断是图片
				var testImg = /^image\/(jpeg|png|jpg|gif)$/.test(file.type);
				var testfile =
					/^application\/(pdf|vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.openxmlformats-officedocument.presentationml.presentation|vnd.openxmlformats-officedocument.wordprocessingml.document)$/
					.test(
						file.type
					);
				if (testImg) {
					this.sendFormat = "图片";
				}
				if (testfile) {
					this.sendFormat = "文件";
				}
				//promise 构造oss参数
				return new Promise((resolve, reject) => {
					api.upload.policy().then((res) => {
						this.host = res.result.host;
						this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
						this.ossForm.callback = res.result.callback;
						this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
						this.ossForm.policy = res.result.policy;
						this.ossForm.signature = res.result.signature;
						this.ossForm.success_action_status = 200;
						resolve(true);
					}).catch((err) => {
						console.log(err);
						reject(false);
					});
				});
			},
			//上传文件
			handleAvatarSuccess(res, file) {
				this.fileForm.fileUrl = res.result.filename;
				this.open(); //打开发送图片 文件的弹窗
				this.compressImage(this.fileForm.fileUrl);
				//  console.log(res.result.filename)
				//  this.dragStatus = false //上传结束隐藏拖拽上传
			},
			//打开弹窗
			open() {
				this.$confirm(this.openForm.openContent, this.openForm.openTitle, {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					center: true,
				}).then(() => {
					this.send();
					// this.dragStatus = false
				}).catch(() => {
					this.dragStatus = false;
				});
			},
			//发送
			send() {
				if (this.id == "") {
					this.$message({
						message: "请先选择需要发送的对象",
						type: "warning",
					});
					return;
				}
				if (
					this.sendFormat == "文本" &&
					(this.content === "" || this.content.trim() === "")
				) {
					return;
				}
				// console.log("发送", this.targetUser.memberId);
				var conversation = this.RongIMClient.Conversation.get({
					// targetId: '1413421948019470337', //别人id memberId
					// targetId: '1412719643267248129', //彬哥id memberId
					targetId: this.id, //选择的memberId
					// type: RongIMLib.CONVERSATION_TYPE.PRIVATE, //个人
					// type: RongIMLib.CONVERSATION_TYPE.GROUP, //群组
					type: RongIMLib.CONVERSATION_TYPE[this.type], //个人
				});
				if (this.sendFormat == "文本") {
					console.log("发送文本");
					this.$store.commit("changeMsgList");
					this.textSend(conversation);
				} else if (this.sendFormat == "图片" || this.sendFormat == '表情') {
					console.log("发送图片");
					this.imgSend(conversation);
					this.$store.commit("changeMsgList");
				} else {
					console.log("发送文件");
					this.fileSend(conversation);
					this.$store.commit("changeMsgList");
				}
			},
			//发送文本消息
			textSend(conversation) {
				conversation.send({
					messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg' 文本类型
					content: {
						content: this.content, // 文本内容
					},
				}).then((message) => {
					console.log("发送文字消息成功", message);
					let obj = {};
					obj = message
					obj.avatar = this.loginMsg.avatar
					obj.memberId = this.targetUser.memberId;
					this.$emit('sendSuccess', obj)
					// this.$emit("sendSuccess", Object.assign(obj, message));
					this.content = "";
				}).catch((err) => {
					switch (err.code) {
						case 22406:
							this.$message({
								message: "您不在群组中",
								type: "warning",
							});
							break;
						case 22408:
							this.$message({
								message: "你在群组中被禁言",
								type: "warning",
							});
							break;
						case 23406:
							this.$message({
								message: "您不在聊天室中",
								type: "warning",
							});
							break;
						case 23408:
							this.$message({
								message: "您在聊天室被禁言",
								type: "warning",
							});
							break;
						case 23409:
							this.$message({
								message: "您已被踢出聊天室并不允许加入",
								type: "warning",
							});
							break;
						case 22410:
							this.$message({
								message: "聊天室不存在",
								type: "warning",
							});
							break;
						case 405:
							this.$message({
								message: "您已被对方加入黑名单",
								type: "warning",
							});
							break;
						case 20604:
							this.$message({
								message: "您发送消息过快,请稍后再试",
								type: "warning",
							});
							break;
						case 20605:
							this.$message({
								message: "信令被封禁，详细请联系商务，电话：13161856839",
								type: "warning",
							});
							break;
					}
				});
			},
			//发送图片消息
			imgSend(conversation) {
				// console.log("图片消息")
				let messageType = this.sendFormat == '表情' ? 'RC:StkImgMsg' : 'RC:ImgMsg';
				conversation.send({
					messageType: messageType, // 'RC:ImgMsg'  图片类型  RongIMLib.MESSAGE_TYPE.IMAGE
					content: {
						imageUri: this.fileForm.fileUrl,
						content: this.base64Data ||
							'', // 图片的略缩图，必须是 base64 字符串, 类型必须为 jpg，base64 字符串大小不可超过 10 KB，base64 略缩图必须不带前缀
						imageUrl: this.fileForm.fileUrl, // 图片内容
						packageUrl: this.fileForm.fileUrl, // 图片内容
						packageId: this.fileForm.packageId, //表情包id
						stickerId: this.fileForm.stickerId, //表情id
					},
				}).then((message) => {
					console.log("发送图片消息成功", message);
					let obj = {};
					obj.avatar = this.loginMsg.avatar
					obj.memberId = this.targetUser.memberId;
					this.$emit("sendSuccess", Object.assign(obj, message));
					this.fileForm.fileUrl = "";
					this.dragStatus = false;
				}).catch((err) => {
					switch (err.code) {
						case 22406:
							this.$message({
								message: "您不在群组中",
								type: "warning",
							});
							break;
						case 22408:
							this.$message({
								message: "你在群组中被禁言",
								type: "warning",
							});
							break;
						case 23406:
							this.$message({
								message: "您不在聊天室中",
								type: "warning",
							});
							break;
						case 23408:
							this.$message({
								message: "您在聊天室被禁言",
								type: "warning",
							});
							break;
						case 23409:
							this.$message({
								message: "您已被踢出聊天室并不允许加入",
								type: "warning",
							});
							break;
						case 22410:
							this.$message({
								message: "聊天室不存在",
								type: "warning",
							});
							break;
						case 405:
							this.$message({
								message: "您已被对方加入黑名单",
								type: "warning",
							});
							break;
						case 20604:
							this.$message({
								message: "您发送消息过快,请稍后再试",
								type: "warning",
							});
							break;
						case 20605:
							this.$message({
								message: "信令被封禁，详细请联系商务，电话：13161856839",
								type: "warning",
							});
							break;
					}
				});
			},
			//发送文件消息
			fileSend(conversation) {
				console.log("文件消息", this.fileForm)
				conversation.send({
					messageType: 'RC:FileMsg', // 'RC:FileMsg' 文件类型 RongIMLib.MESSAGE_TYPE.FILE
					content: {
						name: this.fileForm.fileName, // 文件名称
						size: this.fileForm.fileSize, //文件大小
						type: this.fileForm.fileSuffix, //文件格式
						fileUrl: this.fileForm.fileUrl, //文件路径  上传到服务器的路径
					},
				}).then((message) => {
					// console.log("发送文字消息成功", message);
					let obj = {};
					obj.avatar = this.loginMsg.avatar
					obj.memberId = this.targetUser.memberId;
					message.content.size = this.fileForm.fileSize;
					this.$emit("sendSuccess", Object.assign(obj, message));
					console.log("发送文件消息成功", obj, message);
					this.fileForm.fileUrl = "";
					this.fileForm.fileName = "";
					this.fileForm.fileSize = "";
					this.fileForm.fileSuffix = "";
					this.dragStatus = false;
				}).catch((err) => {
					switch (err.code) {
						case 22406:
							this.$message({
								message: "您不在群组中",
								type: "warning",
							});
							break;
						case 22408:
							this.$message({
								message: "你在群组中被禁言",
								type: "warning",
							});
							break;
						case 23406:
							this.$message({
								message: "您不在聊天室中",
								type: "warning",
							});
							break;
						case 23408:
							this.$message({
								message: "您在聊天室被禁言",
								type: "warning",
							});
							break;
						case 23409:
							this.$message({
								message: "您已被踢出聊天室并不允许加入",
								type: "warning",
							});
							break;
						case 22410:
							this.$message({
								message: "聊天室不存在",
								type: "warning",
							});
							break;
						case 405:
							this.$message({
								message: "您已被对方加入黑名单",
								type: "warning",
							});
							break;
						case 20604:
							this.$message({
								message: "您发送消息过快,请稍后再试",
								type: "warning",
							});
							break;
						case 20605:
							this.$message({
								message: "信令被封禁，详细请联系商务，电话：13161856839",
								type: "warning",
							});
							break;
					}
				});
			},
			compressImage(file) {
				const that = this;
				// 创建canvas对象
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				// 加载图片并绘制到canvas上
				const img = new Image();
				img.src = file;
				img.setAttribute("crossOrigin", 'Anonymous');
				img.onload = function() {
					// 绘制到canvas上
					canvas.width = img.width;
					canvas.height = img.height;
					ctx.drawImage(img, 0, 0);
					// 设置压缩后的图片大小
					const maxW = 50;
					const maxH = 50;
					let w = img.width;
					let h = img.height;
					if (w > maxW) {
						h = h * maxW / w;
						w = maxW;
					}
					if (h > maxH) {
						w = w * maxH / h;
						h = maxH;
					}
					canvas.width = w;
					canvas.height = h;
					// 绘制压缩后的图片并获取base64编码
					ctx.drawImage(img, 0, 0, w, h);
					that.base64Data = canvas.toDataURL();
					that.base64Data = that.base64Data.split(',')[1];
				}
			}
		},
	};
</script>

<style scoped>
	.send-info {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.send-info .send-info-top {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 44px;
		padding: 10px 20px 0 20px;
		box-sizing: border-box;
	}

	.send-info .send-info-top img {
		width: 24px;
		height: 24px;
		margin-right: 20px;
		cursor: pointer;
	}

	.send-info .info-input {
		height: 116px;
		resize: none;
		border: 0;
		outline: none;
		vertical-align: top;
		background: #f5f7fa;
		padding: 20px;
		font-size: 16px;
		color: #313233;
		box-sizing: border-box;
	}

	.info-input::-webkit-input-placeholder {
		color: #abaeb3;
		letter-spacing: 1px;
	}

	.send-info .send-button {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 56px;
		height: 28px;
		line-height: 28px;
		font-size: 14px;
		color: #ffffff;
		text-align: center;
		letter-spacing: 1px;
		background: #4db4d4;
		border-radius: 14px;
		cursor: pointer;
	}

	.upload-demo {
		background: #f5f7fa;
		border: none;
	}

	/deep/ .el-upload-dragger {
		border: none;
		background: #f5f7fa;
		height: 116px;
	}

	.meme-list {
		width: 100%;
		height: 100px;
		display: flex;
		flex-wrap: wrap;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.meme-list::-webkit-scrollbar {
		width: 0 !important;
	}

	.meme-list-item {
		width: 25px;
		height: 25px;
		flex-shrink: 0;
		margin-right: 18px;
		margin-bottom: 15px;
	}

	.meme-list-item-img {
		width: 100%;
		max-height: 100%;
		flex-shrink: 0;
	}

	.pointer {
		cursor: pointer;
	}

	.m0R {
		margin-right: 0;
	}

	.meme-list-item-small {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		margin-right: 5px;
		margin-bottom: 5px;
	}
</style>
<template>
	<div class="container-box">
		<spider-window-time :time="time" v-if="time" />
		<!-- 普通信息 -->
		<div class="my-message" v-for="(item, index) in messageAll" :key="index">
			<!-- 通知的信息 -->
			<div v-if="item.messageType == 'ST:GrpNtf'" class="notice-message">
				{{item.content.message}}
			</div>
			<!-- 收到的信息 -->
			<div class="friend-message" v-else-if="item.messageDirection == 2">
				<img :src="item.avatar" v-if="item.avatar" class="avatarImg" />
				<div class="empty" v-else></div>
				<!-- <img src="/img/logo@3x.1bd3f2e3.png" v-else class="avatarImg" /> -->
				<div class="chat-content">
					<!-- 信息为文本格式 -->
					<span v-if="item.messageType == 'RC:TxtMsg'">
						{{ item.content.content }}
					</span>
					<!-- 信息为图片格式 -->
					<img v-if="item.messageType == 'RC:ImgMsg'" :src="item.content.imageUri" alt="图片" class="msgImg" />
					<img v-if="item.messageType == 'RC:GiftMsg'" :src="item.content.giftIconUrl" :alt="item.content.giftName"
						class="msgImg" />
					<!-- 动图表情包 -->
					<img v-if="item.messageType == 'RC:StkImgMsg'" :src="item.content.packageUrl" class="msgImg" />
					<span v-if="item.messageType == 'RC:CardMsg'">
						{{item.content.is_CardType == 0 ? '不支持名片消息' : '不支持卡片消息'}}
					</span>
					<span v-if="item.messageType == 'RC:InvitationMsg'">不支持邀请函消息</span>
					<span v-if="item.messageType == 'RC:RecordSpeechMsg'">不支持录音讲话消息</span>
					<span v-if="item.messageType == 'RC:VideoSpeechMsg'">不支持视频讲话消息</span>
					<span v-if="item.messageType == 'RC:SpiderDramaMsg'">不支持蜘影剧消息</span>
					<span v-if="item.messageType == 'RC:SpiderNewsMsg'">不支持蜘影新闻消息</span>
					<span v-if="item.messageType == 'RC:SpiderShadowMsg'">不支持蜘影消息</span>
					<span v-if="item.messageType == 'RC:SCCardMsg'">
						{{item.content.is_CardType == 0 ? '不支持名片消息' : '不支持卡片消息'}}
					</span>
					<span v-if="item.messageType == 'RC:CombineMsg'">不支持转发消息</span>
					<span v-if="item.messageType == 'RC:SightMsg'">不支持视频消息</span>
					<span v-if="item.messageType == 'RC:RcCmd'">不支持消息</span>
					<span v-if="item.messageType == 'RC:VcMsg'">不支持语音消息</span>
					<span v-if="item.messageType == 'RC:HQVCMsg'">不支持语音消息</span>
					<span v-if="item.messageType == 'RC:LBSMsg'">不支持位置消息</span>
					<!-- 文章格式 -->
					<div v-if="item.messageType == 'RC:ArticleMsg'" class="message-circle-box" @click="lookArticleDetail(item)">
						<img :src="item.content.imgUrl" alt="" class="message-circle-box-img" />
						<div class="article-title">{{ item.content.title }}</div>
					</div>
					<!-- 音频格式 -->
					<!-- <audio
                      :src="item.content.fileUrl"
                      width="300px"
                      height="200px"
                      controls
                      v-if="item.content.fileUrl && item.content.duration"
                    />
                    <audio
                      :src="item.content.remoteUrl"
                      width="300px"
                      height="200px"
                      controls
                      v-if="item.content.remoteUrl && item.content.duration"
                    /> -->
					<!-- 位置地图格式 -->
					<!-- <div v-if="item.content.latitude">
                      <el-amap
                        :center="
                          centerMarket(item.content.latitude, item.content.longitude)
                        "
                        :zoom="zoom"
                        :vid="'container' + index"
                        :id="'container' + index"
                        class="map-container"
                      >
                        <el-amap-marker
                          :position="
                            centerMarket(item.content.latitude, item.content.longitude)
                          "
                          :label="{ content: item.content.poi, offset: [10, 12] }"
                        ></el-amap-marker>
                      </el-amap>
                    </div> -->
					<!-- 蜘信卡片格式 -->
					<!-- <div class="spider-message-box-all" v-if="item.content.userId">
                      <div class="spider-message-box">
                        <img
                          :src="item.content.avatar"
                          alt=""
                          class="spider-message-img"
                        />
                        <div>
                          <div class="article-title">{{ item.content.name }}</div>
                        </div>
                      </div>
                      <div class="spider-message-text">蜘信卡片</div>
                    </div> -->
					<!-- 文件格式 -->
					<spider-window-file v-if="item.content.fileUrl && !item.content.duration" :content="item.content" />
				</div>
			</div>
			<!-- 发送的信息 -->
			<div class="my-chat-info" v-else-if="item.messageDirection == 1">
				<div class="my-chat">
					<!-- 通知的信息 -->
					<div v-if="item.messageType == 'ST:GrpNtf'" class="notice-message">
						{{item.content.message}}
					</div>
					<span v-if="item.messageType == 'RC:TxtMsg'">
						{{ item.content.content }}
					</span>
					<img v-if="item.messageType == 'RC:ImgMsg'" :src="item.content.imageUri" alt="图片" class="msgImg" />
					<img v-if="item.messageType == 'RC:GiftMsg'" :src="item.content.giftIconUrl" :alt="item.content.giftName"
						class="msgImg" />
					<!-- 表情 -->
					<img v-if="item.messageType == 'RC:StkImgMsg'" :src="item.content.packageUrl" class="msgImg" />
					<span v-if="item.messageType == 'RC:CardMsg'">
						{{item.content.is_CardType == 0 ? '不支持名片消息' : '不支持卡片消息'}}
					</span>
					<span v-if="item.messageType == 'RC:InvitationMsg'">不支持邀请函消息</span>
					<span v-if="item.messageType == 'RC:RecordSpeechMsg'">不支持录音讲话消息</span>
					<span v-if="item.messageType == 'RC:VideoSpeechMsg'">不支持视频讲话消息</span>
					<span v-if="item.messageType == 'RC:SpiderDramaMsg'">不支持蜘影剧消息</span>
					<span v-if="item.messageType == 'RC:SpiderNewsMsg'">不支持蜘影新闻消息</span>
					<span v-if="item.messageType == 'RC:SpiderShadowMsg'">不支持蜘影消息</span>
					<span v-if="item.messageType == 'RC:SCCardMsg'">
						{{item.content.is_CardType == 0 ? '不支持名片消息' : '不支持卡片消息'}}
					</span>
					<span v-if="item.messageType == 'RC:CombineMsg'">不支持转发消息</span>
					<span v-if="item.messageType == 'RC:SightMsg'">不支持视频消息</span>
					<span v-if="item.messageType == 'RC:RcCmd'">不支持消息</span>
					<span v-if="item.messageType == 'RC:VcMsg'">不支持语音消息</span>
					<span v-if="item.messageType == 'RC:HQVCMsg'">不支持语音消息</span>
					<span v-if="item.messageType == 'RC:LBSMsg'">不支持位置消息</span>
					<spider-window-file v-if="item.content.fileUrl" :content="item.content" />
					<!-- 文章格式 -->
					<div v-if="item.messageType == 'RC:ArticleMsg'" class="message-circle-box" @click="lookArticleDetail(item)">
						<img :src="item.content.imgUrl" alt="" class="message-circle-box-img" />
						<div class="article-title">{{ item.content.title }}</div>
					</div>
				</div>
				<img :src="item.avatar" v-if="item.avatar" class="avatarImg" />
				<div class="empty" v-else></div>
			</div>
		</div>
		<el-dialog :title="articleObj.title" :visible.sync="articleVisble" width="800px" :before-close="articleClose">
			<div class="articleContent" v-html="articleObj.content"></div>
			<div class="articleBottom">
				<el-button type="primary" @click="articleClose">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import spiderWindowFile from "./spider-window-file.vue";
	import SpiderWindowTime from "./spider-window-time.vue";
	import {
		mapState
	} from "vuex";

	export default {
		components: {
			spiderWindowFile,
			SpiderWindowTime
		},
		props: {
			message: {
				type: Array,
				default: null,
			},
		},
		data() {
			return {
				articleObj: {}, //文章弹窗对象
				articleVisble: false, //文章弹窗显示和隐藏
				time: null, //时间
				messageAll: [], //聊天的数组
				historyList: [], //历史消息数组
				targetInfo: {}, //目标信息
				userInfo: {}, //用户信息
				center: [118.187551, 24.487623],
				zoom: 30,
				firstStatus: true,
			};
		},
		watch: {
			//发送的信息
			message(newVal, oldVal) {
				this.setTime();
				oldVal = newVal;
				this.messageAll = this.historyList.concat(newVal);
				this.$nextTick(() => {
					this.$emit("scrpllEnd");
				});
			},
			//监听收发消息
			updateList(newVal, oldVal) {
				// this.messageAll = [];
				// this.getHistoryMsg(this.targetInfo, this.userInfo);
			},
		},
		computed: {
			...mapState({
				helloss: (contex) => contex.user.helloWord,
				//获取到vuex融云实例化的数据
				RongIMClient: (state) => state.rongyun.RongIMClient,
				updateList: (state) => state.rongyun.updateList, //更新列表
			}),
		},
		methods: {
			// 查看文章详情
			lookArticleDetail(val) {
				this.$api.article.articleDetail(val.content.articleId).then((res) => {
					console.log("文章详情", res.result);
					this.articleObj = res.result
					this.articleVisble = true
				});
			},
			//关闭文章弹窗
			articleClose() {
				this.articleVisble = false
				this.articleObj = {}
			},
			// 点击喇叭图标, 开始播放音频
			handlePlayAudio() {
				// console.log(111)
				this.$refs.audio.play(); // 这里使用了audio的原生开始播放事件,同样不加on, 并使用ref获取dom
				this.isPlay = true;
			},
			// 音频停止后, 把喇叭置灰
			audioEnd() {
				this.isPlay = false;
			},
			//初始化信息 两个参数 第一个参数是目标用户的信息 第二个参数是本人的信息
			init(targetUser, userInfo) {
				//  console.log("这里初始化",targetUser,userInfo)
				this.$store.commit("setTargetId", targetUser.targetId);
				//获取历史消息 把两个参数都传过去 需要获取完信息后把目标人和本人的一些信息补充进去
				this.getHistoryMsg(targetUser, userInfo);
			},
			//地图标记点 一定要有这步 要不然不显示
			centerMarket(latitude, longitude) {
				return [longitude, latitude];
			},
			//设置时间
			setTime() {
				if (this.message.length > 0) {
					let time = this.message[0].sentTime;
					this.time = this.timeFormat(time);
					// console.log(this.time);
				}
			},
			//改变第一次的状态为true
			changeFirstStatus() {
				this.messageAll = [];
				this.firstStatus = true;
			},
			//添加发送的消息 让看起来不用每次都要请求一遍
			addSendMsg(val) {
				this.messageAll.push(val);
				this.$emit("scrpllEnd");
			},
			//获取历史消息
			getHistoryMsg(targetInfo, userInfo) {
				if (!this.firstStatus) {
					return;
				}
				this.firstStatus = false;
				this.targetInfo = targetInfo;
				this.userInfo = userInfo;
				this.historyList = [];
				// console.log("===",targetInfo)
				var conversation = this.RongIMClient.Conversation.get({
					targetId: targetInfo.from == "group" ? targetInfo.id : targetInfo.memberId,
					type: targetInfo.from == "person" ?
						RongIMLib.CONVERSATION_TYPE.PRIVATE : RongIMLib.CONVERSATION_TYPE.GROUP,
				});
				var option = {
					timestamp: +new Date(),
					count: 20,
				};
				conversation.getMessages(option).then((result) => {
					var list = JSON.parse(JSON.stringify(result.list)); // 历史消息列表
					var hasMore = result.hasMore; // 是否还有历史消息可以获取
					// console.log("历史消息", list);
					// 添加头像 messageDirection 1发送 2接收
					list.forEach((item) => {
						if (item.messageDirection == 1) {
							this.$set(item, "avatar", userInfo.avatar);
						}
						if (item.messageDirection == 2) {
							//是私信的是有头像的 不用依次请求
							if (this.targetInfo.from == "person") {
								this.$set(item, "avatar", targetInfo.avatar);
							} else {
								//请求每个信息的头像
								this.$api.member.memberInfoById(item.senderUserId).then((ress) => {
									this.$set(item, "avatar", ress.result.avatar);
								});
							}
						}
					});
					//合并历史消息和传来的信息列表
					this.messageAll = list.concat(this.messageAll);
					console.log("消息列表", this.messageAll);
					//保存一下请求的聊天记录 因为 收发消息都要合并这个历史消息数组
					this.historyList = JSON.parse(JSON.stringify(list));
					this.$emit("scrpllEnd");
				}).catch((err) => {
					this.$ryError(err.code);
				});
			},
			// 初始化地图
			initMap(id, latitude, longitude) {
				if (id) {
					console.log(id, latitude, longitude);
					setTimeout(() => {
						var map = new AMap.Map(id, {
							center: new AMap.LngLat(longitude, latitude),
							zoomEnable: true, //是否禁止缩放
							zoom: 12, //缩放比例
							dragEnable: false, //禁止拖动
							cursor: "hand", // 鼠标在地图上的表现形式，小手
							label: {
								content: "1111",
								offset: [10, 12]
							}, //地图上显示的文字
						});
						// 初始化工具条
						map.plugin(["AMap.ToolBar"], function() {
							map.addControl(new AMap.ToolBar());
						});
						console.log(1);
					}, 500);
				}
			},
			//日期格式化 x月x日 xx:xx
			timeFormat(val) {
				let date = new Date(val);
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let hour = date.getHours();
				let minute = date.getMinutes();
				month = month > 9 ? month : "0" + month;
				day = day > 9 ? day : "0" + day;
				hour = hour > 9 ? hour : "0" + hour;
				minute = minute > 9 ? minute : "0" + minute;
				return month + "月" + day + "日" + " " + hour + ":" + minute;
			},
			//设置图片的大小
			imgStyle(val, index) {
				var img = document.createElement("img");
				img.src = val.content.imageUri;
				console.log(img.naturalWidth, img.naturalHeight);
				this.messageAll[index].content.width = img.naturalWidth / 4 + "px";
				this.messageAll[index].content.height = img.naturalHeight / 4 + "px";
				this.$forceUpdate();
				// img.onload = () => {
				//   // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
				//   // resolve(img.naturalWidth, img.naturalHeight);   //  2064,4608
				// };
			},
		},
	};
</script>

<style scoped>
	.my-message {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: relative;
		width: 100%;
	}

	.my-chat-info {
		display: flex;
		width: 100%;
		margin-bottom: 20px;
	}

	.empty {
		width: 42px;
		height: 42px;
		margin-left: 8px;
		border-radius: 50%;
		background: #fff;
	}

	.notice-message {
		display: none;
		align-self: center;
		flex-direction: row;
		justify-content: center;
		padding: 5px 10px;
		font-size: 12px;
		color: #999;
		background: white;
		border-radius: 50px;
	}

	.my-chat-info .my-chat {
		word-break: break-all;
		display: inline-block;
		padding: 12px;
		font-size: 16px;
		color: #313233;
		background: #ffffff;
		position: relative;
		border-radius: 5px;
		max-width: 50%;
		margin-right: 8px;
		margin-left: auto;
	}

	.my-chat-info .my-chat::before {
		position: absolute;
		right: -8px;
		top: 8px;
		content: "";
		border-left: 10px solid #ffffff;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}

	.friend-message {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin-bottom: 20px;
		width: 100%;
	}

	.friend-message .chat-content {
		word-break: break-all;
		display: inline-block;
		font-size: 16px;
		color: #313233;
		background: #ffffff;
		position: relative;
		border-radius: 5px;
		margin-left: 8px;
		margin-right: auto;
		padding: 12px;
		max-width: 50%;
	}

	.pad {
		padding: 12px;
		box-sizing: border-box;
	}

	.friend-message .chat-content::before {
		position: absolute;
		left: -8px;
		top: 8px;
		content: "";
		border-right: 10px solid #ffffff;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}

	.avatarImg {
		width: 42px;
		height: 42px;
		margin-right: 8px;
		border-radius: 50%;
	}

	.message-circle-box {
		width: 180px;
		height: 50px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.message-circle-box>.message-circle-box-img {
		flex-shrink: 0;
		width: 50px;
		height: 50px;
	}

	.article-title {
		font-size: 14px;
		color: #313233;
		margin-left: 8px;
	}

	.spider-message-box {
		width: 180px;
		height: 50px;
		border-radius: 10px;
		display: flex;
		align-items: center;
	}

	.spider-message-box>.spider-message-img {
		flex-shrink: 0;
		width: 50px;
		height: 50px;
	}

	.spider-message-text {
		margin-top: 10px;
		font-size: 10px;
		color: #313233;
		margin-left: 8px;
		width: 100%;
		margin-left: -2px;
		border-top: 1px solid #cccccc;
		padding-top: 5px;
	}

	.map-container {
		width: 300px;
		height: 180px;
	}

	.articleBottom {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}

	.articleContent {
		width: 100%;
		height: 500px;
		overflow-y: scroll;
	}

	.msgImg {
		max-width: 100%;
		max-height: 100%;
		margin-right: 8px;
		border-radius: 5px;
	}
</style>